<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/modules`"
                title="Modules"
                :columns="columns"
                routerpath="/setup/addmodules"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Modules' : 'Add Modules'}`,
        submitRouterPath: '/setup/modules',
        method: 'post',
        action: 'add',
        getEditValue: `${baseApi}/getmodulesById`,
        url: `${baseApi}/modules`,
        gridForm: true,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          // {
          //   name: "active",
          //   value: 1,
          //   type: "hidden",
          // },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Name',
            required: true,
            rules: 'required'
          },
            {
            label: 'Sort',
            name: 'sort',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Sort',
            required: true,
            rules: 'required'
          },

          {
            label: 'URL',
            name: 'url',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter URL'
            // rules: 'required'
          },
          {
            label: 'Icon',
            name: 'icon',
            value: '',
            type: 'text',
            class: 'col-md-12',
            placeholder: 'Enter Icon',
            required: true
            // rules: 'required'
          },
          {
            label: 'Softwares',
            name: 'sid',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            dropdownname: 'sid',
            url: 'getsoftwares',
            required: true,
            rules: 'required',
            responseValue: 'id',
            options: []
          },
          {
            label: 'Modules',
            name: 'parent',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            dropdownname: 'parent',
            url: 'getmodules',
            rules: 'required',
            responseValue: 'id',
            options: []
          },
          {
            label: 'Installed',
            name: 'installed',
            value: 'true',
            checked: 'true',
            type: 'switch',
            class: 'col-md-6'
          }
        ]
      },
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: "Search ID",
        //   },
        // },
        {
          label: 'Module Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Module Name'
          }
        },
        {
          label: 'Software',
          field: 'softwarename',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Software'
          }
        },
        {
          label: 'Installed',
          field: 'installed',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Installed'
          }
        },
        {
          label: 'Enter By',
          field: 'enterby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Enter Date',
          field: 'created_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        {
          label: 'Modified By',
          field: 'modifiedby',
          type: 'dropdown',
          url: 'getUsers',
          responseValue: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Modified Date',
          field: 'updated_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date'
          }
        },
        // {
        //   label: "Image",
        //   field: "image",
        // },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  },
  mounted () {
    console.log("Modules Called")
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },
  beforeMount () {
    this.getDropdowns()
  },
  methods: {
    async getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })

      this.assignToFormOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
